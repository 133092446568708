<template>
  <b-modal id="bv-modal-payments" hide-footer>
    <template #modal-title>
      {{
        form.id === null
          ? "Agregar Nuevo Método De Pago"
          : "Editar Método De Pago"
      }}
    </template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-form-group class="col-md-12" label="Nombre" label-for="Nombre">
            <ValidationProvider
              name="Tipo"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-input
                v-model="form.type"
                type="text"
                placeholder="Nuevo método de pago"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              >
              </b-form-input>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
        </b-row>
        <hr />
        <b-button variant="primary" block type="submit">Guardar</b-button>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
import {mapActions} from 'vuex'
export default {
  created() {
    core.index();
  },
  data() {
    return {
      form: {
        id: null,
        type: null,
      },
    };
  },
  methods: {
    ...mapActions({
      createOrUpdate: "payments/storePayment",
    }),
    showModal(item) {
      this.form = { id: null, type: null };
      if (item) {
        this.form = {
          id: item.id,
          type: item.type,
        };
      }
      this.$bvModal.show("bv-modal-payments");
    },
    async onSubmit() {
      try {
        const res = await this.createOrUpdate(this.form);
        this.form = {
          id: null,
          type: null,
        };
        this.$bvModal.hide("bv-modal-payments");
        core.showSnackbar("success", res);
      } catch (error) {
        console.log(error);
        this.$bvModal.hide("payments");
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
  },
};
</script>