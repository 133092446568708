<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Tipos de Pagos</h4>
          </template>
          <template v-slot:headerAction>
            <b-button
              variant="primary"
              @click="$refs.createOrUpdate.showModal()"
              >Agregar Nuevo</b-button
            >
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table
                  hover
                  :items="getListPayment"
                  :fields="columns"
                  :busy="
                    !Array.isArray(getListPayment) || getListPayment.length === 0
                      ? true
                      : false || isBusy == true
                  "
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <div v-if="isBusy">
                        <b-spinner type="grow" class="align-middle"></b-spinner>
                        <strong> Cargando datos...</strong>
                      </div>
                      <span class="h5" v-else>Sin registros</span>
                    </div>
                  </template>
                  <template v-slot:cell(type)="data">
                    <span v-if="!data.item.editable">{{ data.item.type }}</span>
                    <input
                      type="text"
                      v-model="data.item.type"
                      v-else
                      class="form-control"
                    />
                  </template>
                  <template v-slot:cell(action)="data">
                    <div v-if="!data.item.editable">
                      <b-button
                        v-b-tooltip.hover
                        title="Editar"
                        variant=" iq-bg-warning mr-1 mb-1"
                        size="sm"
                        @click="$refs.createOrUpdate.showModal(data.item)"
                        ><i class="ri-ball-pen-fill ml-1"></i
                      ></b-button>
                      <b-button
                        v-b-tooltip.hover
                        title="Eliminar"
                        variant=" iq-bg-danger mr-1 mb-1"
                        size="sm"
                        @click="showMsgBoxTwo(data.item)"
                        ><i class="ri-delete-bin-line ml-1"></i
                      ></b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <modals ref="createOrUpdate" />
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { mapActions, mapGetters } from 'vuex'
import modals from '@/components/core/modals/PaymentsModals'
export default {
  name: 'ListPayment',
  components: {
    modals
  },
  async created () {
    this.$store.commit("SET_BREADCRUMB", this.items);
    core.index()
    await this.listPayment()
  },
  methods: {
    ...mapActions({
      listPayment: 'payments/listPayment',
      destroy: 'payments/destroyPayment'
    }),
    
    showMsgBoxTwo(item) {
        this.boxTwo = ''
        this.$bvModal.msgBoxConfirm('¿Desea eliminar este registro?.', {
          title: 'Confirmación',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Si',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
         .then(value => {
            value ? this.remove(item) : ''
          })
          .catch(err => {
            console.log(err)
          })
      },
    async remove (item) {
      try {
        this.$store.commit('run')
        await this.destroy(item.id)
        this.$store.commit('stop')
        core.showSnackbar('success', 'Registro eliminado')
      } catch (error) {
        this.$store.commit('stop')
        const errors = error.response.data.errors
        for (const key in errors) {
          core.showSnackbar('error', errors[key][0])
          return false
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getListPayment: 'payments/getPayment'
    })
  },
  data () {
    return {
      isBusy: false,
      columns: [
        { label: 'Nombre', key: 'type', class: 'text-center' },
        { label: 'Acciones', key: 'action', class: 'text-center' }
      ],
      rows: [],
      items: [
        {
          html: 'Inicio',
          to: '/'
        },
        {
          text: 'Tipos de Pagos',
          active: true
        }
      ]
    }
  }
}
</script>
